* {
  box-sizing: border-box;
  list-style-type: none;
}

body, html {
  color: #222;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: url("puu.49b041ce.c28b4a12.jpeg") 0 0 / cover fixed;
  font-family: Anonymous Pro, monospace;
}

main {
  background: #0006;
  height: 100%;
}

article {
  color: #fff;
  padding: 40px;
}

article ul {
  padding-left: 20px;
}

article > ul {
  padding: 0;
}

article > ul > li {
  padding-bottom: 30px;
}

article > ul li:last-child {
  padding-bottom: 0;
}

article p {
  margin-top: 0;
  line-height: 1.33em;
}

article > figure {
  float: left;
  padding: 0 20px 0 0;
}

details {
  color: #fff;
}

button {
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background: #00ced1;
  border: 2px solid #555;
  border-radius: 100%;
  width: 33px;
  height: 33px;
  display: block;
  transform: scale(.5);
}

button:hover {
  z-index: 9999;
  filter: drop-shadow(6px 6px 6px #0009);
}

canvas {
  filter: drop-shadow(0 0 1px #0003);
}

.player-container {
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  width: 250px;
  padding: 25px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.name {
  padding-bottom: 10px;
  font-size: 18px;
}

.button {
  color: #ddd;
  filter: drop-shadow(3px 3px 4px #00000080);
  margin: 0 4px;
  font-size: 48px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer !important;
}

.button:hover, .button:active {
  color: #fff;
  filter: drop-shadow(0 0 2px #000000bf);
  transform: scale(1.25);
}

.button-animation {
  animation: 6s ease-in-out infinite alternate scale;
}

.button-animation:hover {
  animation: 1.64s ease-in-out infinite alternate scale-hover;
}

.lyrics-container {
  position: inherit;
  text-align: center;
  pointer-events: none;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: table;
}

.lyrics {
  pointer-events: none;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  font-size: 64px;
  display: table-cell;
}

.play:before {
  content: ">";
}

.pause:before {
  content: "×";
}

.prev:before {
  content: "<<";
}

.next:before {
  content: ">>";
}

.timeline-container {
  filter: drop-shadow(2px 2px 3px #0003);
  background: #999;
  border: thin solid #777;
  border-radius: 3px;
  width: 100%;
  margin-top: 18px;
}

.timeline {
  background: #eee;
  width: 0;
  height: 5px;
}

.playing {
  z-index: 9999;
  animation: 1.66s ease-in-out infinite alternate scale-hover;
}

.activated {
  background: #2e8b57bf;
}

.container {
  filter: blur(attr(data-blur-radius));
  width: 100%;
  position: absolute;
}

.gui {
  z-index: 9999;
  position: relative;
}

.gui input {
  display: block;
}

.gui-container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 20px;
  position: absolute;
}

summary {
  font-size: 16px;
  list-style-type: disclosure-closed !important;
}

.summary-open {
  list-style-type: disclosure-open !important;
}

.slider {
  margin: 20px 0 0;
}

.slider:before {
  color: #fff;
  content: attr("data-value");
  width: auto;
  height: 24px;
  display: block;
  position: absolute;
  transform: translate(0, -16px);
}

.songs-container {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

#app {
  position: absolute;
}

.buttons {
  width: 0;
  height: 0;
  position: absolute;
}

.tree {
  margin: 0;
  position: fixed;
}

::marker {
  margin-right: 16px;
}

input[type="range"] {
  -webkit-appearance: none;
  background: none;
  width: 100%;
  height: 38px;
  margin: 10px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  animate: .2s;
  background: #ccc;
  border-radius: 7.5px;
  width: 100%;
  height: 15px;
  box-shadow: 2px 2px 3px #00000054;
}

input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  background: #666;
  border: 2px solid #ccc;
  border-radius: 100%;
  width: 15px;
  height: 15px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #fff;
}

input[type="range"]:focus::-webkit-slider-thumb {
  border: 2px solid #fff;
}

input[type="range"]::-moz-range-track {
  cursor: pointer;
  animate: .2s;
  background: #ccc;
  border-radius: 7.5px;
  width: 100%;
  height: 15px;
  box-shadow: 2px 2px 3px #00000054;
}

input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: #666;
  border: 2px solid #ccc;
  border-radius: 100%;
  width: 11px;
  height: 11px;
}

input[type="range"]::-ms-track {
  cursor: pointer;
  animate: .2s;
  color: #0000;
  background: none;
  border-color: #0000;
  width: 100%;
  height: 10px;
}

input[type="range"]::-ms-fill-lower {
  background: #3071a9;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000;
}

input[type="range"]::-ms-fill-upper {
  background: #3071a9;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000;
}

input[type="range"]::-ms-thumb {
  cursor: pointer;
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  width: 15px;
  height: 30px;
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #3071a9;
}

nav ul:not(ul:first-child) {
  margin: 0;
  padding: 20px;
  font-size: 18px;
}

nav ul:first-child {
  margin: 0;
  padding: 0;
  display: inline-table;
}

nav ul {
  display: inline-table;
}

nav ul li {
  display: table-cell;
}

nav ul li input {
  display: none;
}

nav ul li a {
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

header {
  text-align: right;
  background: #000a;
  border-bottom: thin dashed #ffffffbf;
  width: 100%;
  padding: 20px;
  font-size: 32px;
}

figure {
  margin: 0;
}

figure a img {
  width: 100%;
}

@media (width >= 1000px) {
  article {
    padding: 40px 20%;
  }
}

@keyframes scale {
  0% {
    background: #00ced1;
    transform: scale(.5);
  }

  50% {
    background: gold;
  }

  100% {
    background: #ff4500;
    transform: scale(1);
  }
}

@keyframes scale-hover {
  0% {
    background: #ff4500;
    transform: scale(1.5);
  }

  50% {
    background: gold;
  }

  100% {
    background: #00ced1;
    transform: scale(2);
  }
}

@keyframes blur {
  from {
    filter: blur();
  }

  to {
    filter: blur(10px);
  }
}
/*# sourceMappingURL=main.9a0c5389.css.map */
