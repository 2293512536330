bg-color = #283747
txt-color = white

* 
  box-sizing:border-box
  list-style-type:none

body, html
    width:100%
    height:100%
    padding: 0
    margin: 0
    color:#222
    -webkit-tap-highlight-color transparent

body 
  font-family:Anonymous Pro,monospace
  background:url('../images/puu.49b041ce.jpg')
  background-size:cover
  background-attachment: fixed

main
    height: 100%
    background: #0006

article
    color: #fff
    padding: 40px
    ul
        padding-left 20px
    > ul
        padding 0
        > li
            padding-bottom: 30px
        li:last-child
            padding-bottom: 0
    p
        line-height: 1.33em
        margin-top: 0
    > figure
        padding: 0 20px 0 0
        float: left

details
    color: white

button
    background: darkturquoise
    border solid 2px #555
    border-radius: 100%
    width: 33px
    height: 33px
    display: block
    white-space: nowrap
    user-select none
    transform scale(0.5)
    &:hover
        z-index: 9999
        filter drop-shadow(6px 6px 6px rgba(0,0,0,0.6))

canvas
    filter drop-shadow(0px 0px 1px rgba(0,0,0,0.2))

.player-container
    display block
    position absolute
    right 0
    top 0
    color white
    padding 25px
    width 250px
    text-align center
    vertical-align middle
    user-select none

.name
    font-size 18px
    padding-bottom 10px

.button
    cursor pointer !important
    display inline-block
    font-size 48px
    font-weight bold
    color #ddd
    filter drop-shadow(3px 3px 4px rgba(0,0,0,0.5))
    margin 0 4px
    &:hover, &:active
      color white
      transform scale(1.25)
      filter drop-shadow(0px 0px 2px rgba(0,0,0,0.75))

.button-animation
    animation 6s infinite scale alternate ease-in-out
    &:hover
      animation 1.64s infinite scale-hover alternate ease-in-out

.lyrics-container
    position inherit
    width 100%
    height 100%
    display table
    text-align center
    pointer-events none
    z-index 9999
    padding 20px

.lyrics
    font-size 64px
    pointer-events none
    display table-cell
    vertical-align middle
    width 100%
    text-align center


@keyframes scale
    0%
        transform scale(0.5)
        background: darkturquoise
    50%
        background gold
    100%
        background orangered
        transform scale(1)

@keyframes scale-hover
    0%
        transform scale(1.5)
        background orangered
    50%
        background gold
    100%
        background: darkturquoise
        transform scale(2)

.play
    &:before
      content ">"
.pause
    &:before
      content "×"
.prev
    &:before
      content "<<"
.next
    &:before
      content ">>"

.timeline-container
    border solid thin #777
    width 100%
    margin-top 18px
    border-radius 3px
    background #999
    filter drop-shadow(2px 2px 3px rgba(0,0,0,0.2))

.timeline
    width 0
    height 5px
    background #eee


.playing
    //background: rgba(46,139,87,0.75)
    animation 1.66s infinite scale-hover alternate ease-in-out
    z-index 9999

.activated
    background: rgba(46,139,87,0.75)

@keyframes blur
    from 
        filter: blur(0px)
    to
        filter: blur(10px)

.container
    position: absolute
    width: 100%
    filter: blur(attr(data-blur-radius))

.gui
    z-index: 9999
    position: relative
    input
        display: block

.gui-container
    position: absolute
    margin: 20px 20px
    cursor pointer
    user-select none

summary
    font-size 16px
    list-style-type disclosure-closed !important

.summary-open
    list-style-type disclosure-open !important

.slider
    margin: 20px 0 0 0
    &:before
        color: white
        content: attr("data-value")
        width: auto
        height: 24px
        display: block
        position: absolute
        transform: translate(0, -16px)

.songs-container
    display: block
    position: absolute
    width: 100%
    height: 100%

#app
  position: absolute

.buttons
  position: absolute
  width: 0
  height: 0

.tree
  margin: 0
  position: fixed

::marker
    margin-right 16px

input[type=range] 
    height: 38px
    -webkit-appearance: none
    margin: 10px 0
    width: 100%
    background: transparent

input[type=range]:focus 
    outline: none

input[type=range]::-webkit-slider-runnable-track 
    width: 100%
    height: 15px
    cursor: pointer
    animate: 0.2s
    box-shadow: 2px 2px 3px rgba(0,0,0,0.33)
    background: #ccc
    border-radius: 7.5px

input[type=range]::-webkit-slider-thumb 
    height: 15px
    width: 15px
    border-radius: 100%
    border: solid 2px #ccc
    background: #666
    cursor: pointer
    -webkit-appearance: none

input[type=range]:focus::-webkit-slider-runnable-track 
    background: #fff

input[type=range]:focus::-webkit-slider-thumb 
    border: solid 2px #fff

input[type=range]::-moz-range-track 
    width: 100%
    height: 15px
    cursor: pointer
    animate: 0.2s
    box-shadow: 2px 2px 3px rgba(0,0,0,0.33)
    background: #ccc
    border-radius: 7.5px

input[type=range]::-moz-range-thumb 
    height: 11px
    width: 11px
    border-radius: 100%
    border: solid 2px #ccc
    background: #666
    cursor: pointer

input[type=range]::-ms-track 
    width: 100%
    height: 10px
    cursor: pointer
    animate: 0.2s
    background: transparent
    border-color: transparent
    color: transparent

input[type=range]::-ms-fill-lower 
    background: #3071A9
    border: 1px solid #000000
    border-radius: 10px
    box-shadow: 1px 1px 1px #000000

input[type=range]::-ms-fill-upper 
    background: #3071A9
    border: 1px solid #000000
    border-radius: 10px
    box-shadow: 1px 1px 1px #000000

input[type=range]::-ms-thumb 
    margin-top: 1px
    box-shadow: 1px 1px 1px #000000
    border: 1px solid #000000
    height: 30px
    width: 15px
    border-radius: 5px
    background: #FFFFFF
    cursor: pointer

input[type=range]:focus::-ms-fill-lower 
    background: #3071A9

input[type=range]:focus::-ms-fill-upper 
    background: #3071A9

nav 

    ul:not(ul:first-child) 
        margin:0
        padding:20
        font-size: 18px
    ul:first-child 
        margin: 0
        padding:0
        display: inline-table
    ul 
        display: inline-table
        li
            display: table-cell
            input
                display: none
            a
                display: inline-block
                vertical-align: middle
                user-select: none
                color: #fff
                text-decoration: none
                cursor: pointer

header 
  background: #000a
  text-align:right
  width:100%
  font-size:32px
  padding:20px
  border-bottom:thin dashed hsla(0,0%,100%,.75)

figure
    margin: 0
    a
        img
            width: 100%

@media (min-width:1000px) 
    article
        padding: 40px 20%
  
